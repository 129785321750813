/* *{
  -ms-overflow-style: none;
}
::-webkit-scrollbar{
    display: none !important;
} */
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: overlay;
}
body {
  overflow: overlay;
}

/* Cusomize scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: none;
}

::-webkit-scrollbar-track {
  background: none;
}
 
::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.25); 
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb:hover {
  //background: #555; 
} 



/* A few custom styles for date inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
  background: none !important;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}